import React, { useState, useEffect } from "react";
import * as SurveyJS from "survey-react";
import { Alert, Button } from "@bdo/kitchensink";
import "survey-core/survey.i18n";
import "survey-react/defaultV2.css";
import { StylesManager } from "survey-react";
import * as Shared from "../Shared";
import MarkdownIt from "markdown-it";
import attrs from "markdown-it-attrs";
import FilePondUpload from "../fileUpload/FilePondUpload";

StylesManager.applyTheme("defaultV2");

// register custom file upload question
SurveyJS.ComponentCollection.Instance.add({
  name: "file-upload",
  title: "file-upload",    
  questionJSON: {
      type: "text",
      placeholder: "Upload the files."        
  },
},
);

// register custom file upload widget to survey
SurveyJS.CustomWidgetCollection.Instance.addCustomWidget({
  name: 'file-upload',
  isFit: question => {
    return question.getType() == "file-upload";
  },
  render: question => {
    return <FilePondUpload  question={ question } />
  }
})

export function Survey({
  template,
  allowSaveProgress,
  onSaveProgress,
  mode,
  onComplete,
  data,
  ...props
}) {
  const [surveyModel] = useState(
    new SurveyJS.SurveyModel({
      ...template,
      ...{ focusFirstQuestionAutomatic: false },      
      ...{ "checkErrorsMode" : "onComplete"   }
    })
  );

  // language selection
  const [language, setLanguage] = useState("en");
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingProgress, setIsSavingProgress] = useState(false);
  const markdownConverter = new MarkdownIt();
  markdownConverter.use(attrs, {
    allowedAttributes: ["target"],
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [displaySurvey, setDisplaySurvey] = useState(true);
  const [isFailed, setIsFailed] = useState(false);
  const [wait, setWait] = useState(false);
  let progressTimer = null;

  surveyModel.locale = language;
  surveyModel.completedHtml = "<span></span>";

  useEffect(() => {
    return function clearTimers() {
      if (progressTimer !== null) {
        clearTimeout(progressTimer);
        progressTimer = null;
      }
    };
  }, []);

  function onCurrentPageChanged(sender, options) {
    if ("parentIFrame" in window) {
        window.parentIFrame.scrollToOffset(0, 0);
    }
  }

  function saveProgress() {
    if (allowSaveProgress && typeof onSaveProgress === "function") {
      setShowMessage(false);
      setIsSavingProgress(true);
      setWait(true);
      progressTimer = setTimeout(() => {
        setWait(false);
      }, 2000); // Wait a minimum period of time to allow the UI to give feedback that its doing something.
      onSaveProgress(surveyModel.data, saveProgressSuccess, saveProgressError);
    }
  }

  function saveProgressSuccess() {
    setShowMessage(true);
    setMessage({
      type: "success",
      message: "Progress successfully saved.",
    });
    setIsSavingProgress(false);
  }

  function saveProgressError() {
    setShowMessage(true);
    setMessage({
      type: "error",
      message: "There was an issue saving your progress, please try again.",
    });
    setIsSavingProgress(false);
  }

  async function save(sender, options) {
     setShowMessage(false);
    setIsSaving(true);
    setDisplaySurvey(false);
    options.showDataSavingClear();
    options.showDataSaving("Saving your data to the server...");
    const result = await onComplete(sender, options);
    options.showDataSavingClear();
    if (result === true) {
      setIsSuccess(true);
    } else {
      setIsFailed(true);
    }
    setIsSaving(false);
  }

  function onTextMarkdown(survey, options) {
    if (options.name !== "title") {
      options.html = markdownConverter.render(options.text);
    }
  }

  if (!!data) {
    // fix #105058
    surveyModel.data = data;
    surveyModel.currentPageNo = 0; // force surveyJS to recalculate expressions see: https://surveyjs.io/form-library/documentation/api-reference/survey-data-model#data
  }

  return (
    // TODO: Remove reliance on kitchensink component
    <>
      {isSaving && <Shared.Saving />}
      {isSuccess && <Shared.Success />}
      {isFailed && <Shared.Error />}
      {displaySurvey && (
        <>
          {showMessage && wait === false && (
            <Alert
              {...message}
              data-id="save-progress-result"
              className="mb-3"
              closable
              onClose={() => {
                setShowMessage(false);
                setMessage({});
              }}
            />
          )}
          <div id="survey-actions">
            {allowSaveProgress &&
              mode === "edit" &&
              isSaving === false &&
              isSavingProgress === false &&
              wait === false && (
                <Button
                  type="success"
                  shape="square"
                  iconName="Save"
                  style={{ marginRight: "1em" }}
                  onClick={saveProgress}
                >
                  Save Progress
                </Button>
              )}
            {allowSaveProgress &&
              mode === "edit" &&
              isSaving === false &&
              (isSavingProgress === true || wait === true) && (
                <Button
                  type="success"
                  shape="square"
                  loading
                  style={{ marginRight: "1em" }}
                >
                  Saving
                </Button>
              )}
          </div>
          <SurveyJS.Survey
            model={surveyModel}
            mode={mode}
            onComplete={save}
            onTextMarkdown={onTextMarkdown}
            onCurrentPageChanged={onCurrentPageChanged}
            {...props}
          />
        </>
      )}
    </>
  );
}

import React, {useState,useEffect, useRef} from 'react';
import { FilePond, registerPlugin } from 'react-filepond'; 
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';
import { apiRoutes, buildRoute } from '../../config/apiRoutes';


const getParameters = new URLSearchParams(window.location.search);
const externalCaptureId = getParameters.get('externalCaptureId');

registerPlugin(FilePondPluginFileValidateSize);
registerPlugin(FilePondPluginFileValidateType);

function FilePondUpload(props){ 

  const { question } = props;
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const filePondRef = useRef(null);
  
  useEffect(() => {    
  if(question.value){
    const fileItems = Array.from(question.value).reverse();
    const initialFiles=fileItems.map(item => ({id: item.Id,source: item.FileName, options:{ type:'local', file:{id: item.Id, name: item.FileName, size: item.Size, type: item.Type}}}));
    const previousUploadedFiles=fileItems.map(item => ({Id:item.Id,FileName: item.FileName, Size:item.Size, Type:item.Type}));
    setFiles(initialFiles);
    setUploadedFiles(previousUploadedFiles);  
}
}, []);

useEffect(()=> {
  question.value = uploadedFiles;
},[uploadedFiles, question]);

function deleteFile(fileName)
{
const fileInfo= uploadedFiles.find(o => o.FileName === fileName);
  if(fileInfo){
  var params = JSON.stringify({
    ExternalCaptureId: externalCaptureId,
    FileName: fileInfo.Id
  });

  const request = new XMLHttpRequest();  
  request.open('DELETE', buildRoute(apiRoutes.removeFile));
  request.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
  request.send(params); 
  setUploadedFiles(oldValues => {
     return oldValues.filter(item => item.FileName !== fileInfo.FileName);
   })
  setFiles(oldValues => {
     return oldValues.filter(item => item.filename !== fileInfo.FileName);
   });
  question.value = uploadedFiles.filter(item => item.FileName !== fileInfo.FileName); 
 }
}

   return (
      <FilePond  ref={filePondRef} className='fileUploader'
      files={files}
      onupdatefiles={fileItems => { 
        const duplicates=[];
        fileItems.reverse().forEach((value, index) => {
          if(duplicates.indexOf(value.filename) === -1){
            duplicates.push(value.filename);
            }
            else{
              fileItems[index].abortLoad();
              fileItems.slice(index,1);
            }
          });
        setFiles(fileItems);
        }} 
      iconRemove='<svg width="45px" height="45px" viewBox="0 -30 95 150" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M76.105 19.8015L71.1948 14.8913C70.7583 14.4548 70.3316 14.4451 69.9145 14.8622L45.36 39.4167L20.9747 15.0314C20.5382 14.595 20.1115 14.5853 19.6944 15.0023L15.0025 19.6943C14.5854 20.1113 14.5951 20.5381 15.0316 20.9746L39.4169 45.3599L14.8623 69.9144C14.4453 70.3315 14.455 70.7582 14.8914 71.1947L19.8016 76.1049C20.2381 76.5413 20.6648 76.551 21.0819 76.134L45.6364 51.5794L70.0255 75.9686C70.462 76.405 70.8888 76.4147 71.3058 75.9977L75.9978 71.3057C76.4148 70.8886 76.4051 70.4619 75.9687 70.0254L51.5796 45.6363L76.1341 21.0818C76.5511 20.6647 76.5414 20.2379 76.105 19.8015Z" fill="#fff"></path>
      </svg>'     
      labelFileTypeNotAllowed='file type not allowed'
      allowMultiple={true}
      maxFiles={3}
      maxParallelUploads={3}
      maxFileSize={20000000}
      acceptedFileTypes={['image/*', 'text/plain', 'text/csv', 'application/msword', 'application/pdf', 'application/zip','application/x-zip','application/x-zip-compressed','application/octet-stream', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
      fileValidateTypeLabelExpectedTypes='Expects image, pdf, text, zip, msword'
      allowDrop={true}
      chunkUploads={false}
      instantUpload={true}
      name="files" 
      styleButtonRemoveItemPosition='right'
      labelTapToUndo=''
      labelTapToRetry=''
      labelTapToCancel=''
      labelIdle=' <div class="dropAreaContainer"><div class="uploadIcon"></div><div class="dropAreaText">Drop a file here or <span class="filepond--label-action">Browse</span></div></div><div class="uploadAreaInfo"><i>Maximum of 3 files can be uploaded (20MB size limit per file)</i></div></div>'
      credits={false}
      server={
        {         
        process:  {           
          url: buildRoute(apiRoutes.uploadFiles), 
          onload:(response) => {          
          const uploadedFile = JSON.parse(response);          
          setUploadedFiles(oldArray => [...oldArray,uploadedFile] );
          return uploadedFile.FileName;    
          },           
          ondata: (formData) => {
           formData.append('externalCaptureId', externalCaptureId);
           return formData;
          },            
        },
        revert: (fileName, load, error) => {
          deleteFile(fileName);
          load();
        },
        remove: (fileName,load) => {
          if(files.some(item => item.source === fileName)){
            deleteFile(fileName); 
            load();               
          }
        }
      }}        
      />
 );
}       
export default FilePondUpload;

import React from 'react';
import '../../styles/pages.css';

export default function Success() {
    return (
        <div className="bdo-page success">
            <header className="header-image"/>	
            <h3>Thank you for completing the survey</h3>
            <p>Your response has been saved sucessfully</p>
            <footer>
                <button onClick={() => window.location.assign(window.location.href) }>Start another</button>
            </footer>
        </div>
    );
}
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { apiRoutes, buildRoute } from './config/apiRoutes';
import { Passcode } from './components/passcode/Passcode';
import { Survey } from './components/survey/Survey';
import * as Shared from './components/Shared';
import { useLogging } from './hooks/useLogging';

import '@bdo/kitchensink/lib/styles/index.css'; // TODO: Remove reliance on kitchensink
import '@bdo/kitchensink/lib/styles/components.css'; // TODO: Remove reliance on kitchensink
import './styles/pages.css';
import './styles/surveyJS.css';
import './styles/fonts.css';
import './styles/variables.css';

export default function App() {

    const [states] = useState({
        loading: 'Loading',
        error: 'Error',
        notFound: 'NotFound',
        passcodeOnly: 'PasscodeOnly',
        passcodeAndAnonymous: "PasscodeAndAnonymous",
        passcodeCompleted: 'PasscodeCompleted',
        passcodeSurvey: 'PasscodeSurvey',
        anonymousSurvey: 'AnonymousSurvey'
    });

    const [logError] = useLogging();

    const [componentState, setComponentState] = useState(states.loading);

    const [loadCaptureResponse, setLoadCaptureResponse] = useState(null);
    const [checkPasscodeResponse, setCheckPasscodeResponse] = useState(null);

    const getParameters = new URLSearchParams(window.location.search);
    const externalCaptureId = getParameters.get('externalCaptureId');

    const onPasscodeSuccess = useCallback((passcodeResponse) => {
        setCheckPasscodeResponse(passcodeResponse);
        if (passcodeResponse.completed) {
            setComponentState(states.passcodeCompleted);
        }
        else {
            setComponentState(states.passcodeSurvey);
        }
    }, [states]);

    const onContinueWithoutPasscode = useCallback(() => {
        setComponentState(states.anonymousSurvey);
    }, [states])

    const renderState = {
        [states.loading]: () => {
            return (<Shared.Loading />);
        },
        [states.error]: () => {
            return (<Shared.Error buttonVisible={true} />);
        },
        [states.notFound]: () => {
            return (<Shared.NotFound />);
        },
        [states.passcodeOnly]: () => {
            return (<Passcode
                externalCaptureId={externalCaptureId}
                passcodeSuccessCallback={onPasscodeSuccess}
                allowAnonymousResponse={false}
            />);
        },
        [states.passcodeCompleted]: () => {
            return (
                <Shared.PasscodeComplete />
            );
        },
        [states.passcodeAndAnonymous]: () => {
            return (<Passcode
                externalCaptureId={externalCaptureId}
                passcodeSuccessCallback={onPasscodeSuccess}
                allowAnonymousResponse={true}
                continueWithoutPasscodeCallback={onContinueWithoutPasscode}
            />);
        },
        [states.passcodeSurvey]: () => {
            return (
                <Survey
                    template={checkPasscodeResponse.surveyModel}
                    data={checkPasscodeResponse.userData}
                    mode='edit'
                    onComplete={onComplete}
                    showCompletedPage={true}
                    allowSaveProgress={true}
                    onSaveProgress={onSaveProgress}
                />
            );
        },
        [states.anonymousSurvey]: () => {
            return (
                <Survey
                    template={loadCaptureResponse.surveyModel}
                    mode='edit'
                    onComplete={onComplete}
                    showCompletedPage={true}
                    allowSaveProgress={false}
                />
            );
        },
    }

    useEffect(() => {
        async function fetchData() {
            try {
                setComponentState(states.loading);

                if (externalCaptureId === null || externalCaptureId.length === 0) {
                    setComponentState(states.notFound);
                    return;
                }

                const url = buildRoute(apiRoutes.load, { externalCaptureId: externalCaptureId });

                const result = await axios({
                    url: url,
                    validateStatus: function (status) {
                        return status < 500
                    }
                });

                if (result && result.status === 200) {
                    setLoadCaptureResponse(result.data);

                    if (!result.data.allowPasscodeResponses && !result.data.allowAnonymousResponses) {
                        setComponentState(states.notFound);
                    } else if (result.data.allowPasscodeResponses && result.data.allowAnonymousResponses) {
                        setComponentState(states.passcodeAndAnonymous);
                    } else if (result.data.allowPasscodeResponses) {
                        setComponentState(states.passcodeOnly);
                    } else {
                        setComponentState(states.anonymousSurvey);
                    }
                }

                if (result && result.status === 404) {
                    setComponentState(states.notFound);
                }

                if (result && ![200, 404].includes(result.status)) {
                    setComponentState(states.error);
                    logError("Failed to load capture, non success status code", { result: result });
                }
            }
            catch (ex) {
                setComponentState(states.error);
                logError("Failed to load capture, axios exception", { exception: ex });
            }
        }
        fetchData();
    }, [externalCaptureId, states, logError]);

    async function uploadFiles(sender) {
        let dataResponse = sender.data;
        for (const key in dataResponse) {
            let question = sender.getQuestionByName(key);

            if (question && typeof question.getType === 'function') {
                if (question.getType() === "file") {

                    let fileObject = {
                        externalCaptureId: externalCaptureId,
                        files: dataResponse[key]
                    }
                    const uploadFiles = await axios({
                        url: buildRoute(apiRoutes.uploadFiles),
                        method: 'POST',
                        data: {
                            userData: fileObject
                        }
                    });

                    if (uploadFiles && uploadFiles.status === 200) {
                        dataResponse[key] = uploadFiles.data;
                    }
                    else {
                        return false;
                    }
                }
            }


        }
        return dataResponse;
    }

    async function onComplete(sender, options) {
        try {
            let surveyResponse = sender.data;
            try {
                surveyResponse = await uploadFiles(sender);
            }
            catch (ex) {
                logError("Failed to upload files for response.", { exception: ex, surveyResponse: sender.data });
                return false;
            }

            const result = await axios({
                url: buildRoute(apiRoutes.save),
                method: 'POST',
                data: {
                    captureId: loadCaptureResponse.id,
                    passcodeId: (checkPasscodeResponse !== null ? checkPasscodeResponse.passcodeId : null),
                    userData: surveyResponse,
                    isComplete: true
                }
            });

            if (result && result.status === 200) {
                return true;
            }
        }
        catch (ex) {
            logError("Failed to save completed response.", { exception: ex, surveyResponse: sender.data });
        }

        return false;
    }

    async function onSaveProgress(data, onSuccess, onError) {
        try {

            setCheckPasscodeResponse({ ...checkPasscodeResponse, ...{ userData: data } });

            const result = await axios({
                url: buildRoute(apiRoutes.save),
                method: 'POST',
                data: {
                    captureId: loadCaptureResponse.id,
                    passcodeId: checkPasscodeResponse.passcodeId,
                    userData: data,
                    isComplete: false
                }
            });

            if (result && result.status === 200) {
                if (onSuccess && typeof onSuccess === 'function') {
                    onSuccess();
                }
                return;
            }

            logError("Failed to save survey progress. Non success status code.", { result: result, surveyData: data });
        }
        catch (ex) {
            logError("Failed to save survey progress. Exception caught.", { exception: ex, surveyData: data });
        }

        if (onError && typeof onError === 'function') {
            onError();
        }
    }

    // Render component state
    return (
        <div id="capture-survey" data-iframe-height >
            {renderState[componentState]()}
        </div>
    );
}

export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const apiRoutes = {
    passcode: '/passcode/check',
    load: '/capture/load/:externalCaptureId',
    save: '/response/save',
    uploadFiles: '/response/uploadFiles',
    removeFile: '/response/removeFile'
}

export function buildRoute(route, tokens) {
    let url = `${apiBaseUrl}${route}`;

    if (tokens && typeof tokens === 'object') {
        for (const [key, value] of Object.entries(tokens)) {
            url = url.replace(new RegExp(`:${key}`, 'gi'), value);
        }
    }   

    return url;
}
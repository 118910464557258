import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { useCallback } from "react";

export function useLogging() {
    const appInsights = useAppInsightsContext();
    const appInsightsEnabled = process.env.REACT_APP_APP_INSIGHTS_ENABLED.toLowerCase() === 'false' ? false : true;

    const logError = useCallback(function error(message, data) {
        if (appInsightsEnabled) {
            if (data) {
                appInsights.trackException({ error: new Error(message), properties: data, severityLevel: SeverityLevel.Error });
            }
            else {
                appInsights.trackException({ error: new Error(message), severityLevel: SeverityLevel.Error });
            }
        }
        else {
            console.error({
                error: message,
                data: data
            });
        }
    }, [appInsights, appInsightsEnabled]);

    const logWarning = useCallback(function warn(message, data) {
        if (appInsightsEnabled) {
            if (data) {
                appInsights.trackTrace({ message: message, properties: data, severityLevel: SeverityLevel.Warning });
            }
            else {
                appInsights.trackTrace({ message: message, severityLevel: SeverityLevel.Warning });
            }
        }
        else {
            console.warn({
                message: message,
                data: data
            });
        }
    }, [appInsights, appInsightsEnabled]);

    const logInfo = useCallback(function info(message, data) {
        if (appInsightsEnabled) {
            if (data) {
                appInsights.trackTrace({ message: message, properties: data, severityLevel: SeverityLevel.Information });
            }
            else {
                appInsights.trackTrace({ message: message, severityLevel: SeverityLevel.Information });
            }
        }
        else {
            console.info({
                message: message,
                data: data
            });
        }
    }, [appInsights, appInsightsEnabled]);

    const logEvent = useCallback(function event(name, data) {
        if (appInsightsEnabled) {
            if (data) {
                appInsights.trackEvent({ name: name, properties: data, severityLevel: SeverityLevel.Information });
            }
            else {
                appInsights.trackEvent({ name: name, severityLevel: SeverityLevel.Information });
            }
        }
        else {
            console.log('Event:', {
                name: name,
                data: data
            });
        }
    }, [appInsights, appInsightsEnabled]);

    return [logError, logWarning, logInfo, logEvent];
}
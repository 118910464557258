import React from 'react';
import '../../styles/pages.css';

export default function Saving () {

    return (        
        <div className='bdo-page'> 
            <header className='header-image bdo-spinner-container'>
                <div className="bdo-spinner"/>                 
            </header>
            <h3>Processing data...</h3>
            <p>Please wait.</p>
            <footer>
                
            </footer>
        </div>
    )
}
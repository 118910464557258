import React from 'react';
import '../../styles/pages.css';

export default function Error({ title = "Something went wrong.", message = "Please try again. If the issue persists, please contact your BDO representative.", buttonVisible = false, buttonFunction = () => { window.location.assign(window.location.href) }, buttonText="Try again", ...props }) {
    return (
        <div className='bdo-page error'>
            <header className='header-image'></header>
            <h3>{title}</h3>
            <p>{message}</p>
            {props.children}
            {buttonVisible &&
                <footer>
                    <button onClick={() => typeof buttonFunction === 'function' && buttonFunction()}>{buttonText}</button>
                </footer>
            }
        </div>
    );
}
import React, { useState } from 'react';
import axios from 'axios';
import { apiRoutes, buildRoute } from '../../config/apiRoutes';
import { useLogging } from '../../hooks/useLogging';

export function Passcode({
    externalCaptureId,
    passcodeSuccessCallback,
    allowAnonymousResponse,
    continueWithoutPasscodeCallback }) {

    const logging = useLogging();

    const [passcode, setPasscode] = useState('');
    const [error, setError] = useState(false);
    const [message, setMessage] = useState('');
    const [checkingInput, setCheckingInput] = useState(false);

    function showError(message) {
        setError(true);
        setMessage(message);
        setPasscode('');
    }

    function clearError() {
        setError(false);
        setMessage('');
    }

    function validateInput() {
        if (passcode.trim().length === 0) {
            showError('Please enter a valid passcode.');
            return false;
        } else {
            clearError();
            return true;
        }
    }

    async function checkPasscode() {
        try {
            const result = await axios({
                url: buildRoute(apiRoutes.passcode),
                method: 'POST',
                data: {
                    captureId: externalCaptureId,
                    Passcode: passcode
                }
            });

            if (result && result.status === 200) {
                return result.data;
            } else {
                logging.error("Failed to check passcode, non success status code", { result: result });
            }
        }
        catch (ex) {
            logging.error("Failed to check passcode, axios exception", { exception: ex });
        }
        return null;
    }

    async function onCheckPasscode() {
        setCheckingInput(true);
        if (validateInput()) {
            const result = await checkPasscode(externalCaptureId, passcode);
            if (result && result.isValid) {
                passcodeSuccessCallback(result);
                return;
            }
            else {
                showError('Invalid passcode entered, please try again.');
            }
        }
        setCheckingInput(false);
    }

    return (
        <div className="passcodePage">
            <div className="passCard">
                <section className='capturePasscode'>
                    {
                        allowAnonymousResponse ?
                            <>
                                <h2>Passcode</h2>
                                <p>Please enter a passcode if you have been provided with one.</p>
                            </>
                            :
                            <>
                                <h2>Passcode Required</h2>
                                <p>Please enter a passcode in order to access this resource.</p>
                            </>
                    }
                    <form>

                        <section>
                            <label htmlFor="pascodeInput" className="form-label">Passcode</label>
                            <input type="password" value={passcode} onChange={(e) => setPasscode(e.target.value)} name="passcodeInput" />
                            {error && <p className="alert alert-danger">{message}</p>}
                        </section>
                        <section>
                            {
                                checkingInput ?
                                    <button name="checkPasscode" disabled>Working...</button>
                                    :
                                    <button name="checkPasscode" onClick={(e) => { e.preventDefault(); onCheckPasscode() }}>Submit</button>
                            }
                            {allowAnonymousResponse && <><a name="continueWithoutPasscode" className='continueWithoutPasscode' onClick={(e) => { e.preventDefault(); continueWithoutPasscodeCallback() }}><p>or continue without passcode</p></a></>}
                        </section>
                    </form>
                </section>
            </div>
        </div>
    );
}